<template>
  <div class="px-0 lg:px-0 col-span-12 lg:col-span-8">
    <LoadingSkeleton v-if="isLoadingProduct" />
    <div v-else class='space-y-6 p-4 lg:p-0'>
      <ListingHeader v-if="!isCreate" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import LoadingSkeleton from "@/components/UI/Indication/LoadingSkeleton";
import ListingHeader from "@/components/views/Listing/ListingHeader";
import { useRoute, useRouter } from "vue-router";

export default {
  name: 'Listing',
  components: {
    LoadingSkeleton,
    ListingHeader
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoadingProduct = computed(() => store.state.product.isLoadingProduct);

    router.beforeResolve((to) => {
      if (store.state.product.mode !== 'create') {
        const children = computed(() => store.state.product.product.children);

        const unitsList = children.value.map(child => ({
          name: child.translation.title,
          id: child.id,
          routeName: 'unit'
        }))

        Object.assign(to.meta, { props: {
          unitsList
        } });
      }
    })

    const children = computed(() => store.state.product.product.children);

    const isCreate = computed(() => !route.params.id);

    onMounted(() => {
      if (isCreate.value) {
        store.commit('product/setInitialState');
        store.commit('product/setProductMode', 'create');
      } else {
        store.commit('product/setProductMode', 'edit');
      }
    })

    return {
      isLoadingProduct,
      children,
      isCreate
    }
  }
};
</script>

