<template>
  <div>
    <div class="animate-pulse mb-8">
      <div class="flex justify-between items-center mb-4">
        <div class="flex flex-col">
          <div class="w-28 h-8 bg-gray-200 rounded-lg mb-2"></div>
          <div class="flex space-x-4">
            <div class="w-20 h-4 bg-gray-200 rounded-lg"></div>
            <div class="w-28 h-4 bg-gray-200 rounded-lg"></div>
          </div>
        </div>
        <div class="w-20 h-12 bg-gray-200 rounded-lg"></div>
      </div>
      <div class="w-full h-72 rounded-lg bg-gray-200">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSkeleton"
};
</script>

<style scoped>

</style>
