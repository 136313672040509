<template>
  <div class='flex justify-between gap-4 items-center'>
    <div class="flex-wrap">
      <h2 class="font-bold text-xl md:text-2xl text-universeBlue">{{ title }}</h2>
      <div class="flex gap-2 items-center mt-1">
        <span class="hidden items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          {{ verifiedAt }}
        </span>
        <span v-if="createdAt" class="text-xs text-gray-500">
          Created {{ dayjs(createdAt).format('DD MMM. YYYY') }}
        </span>
      </div>
    </div>
    <div class='md:justify-end md:flex-row-reverse flex flex-shrink-0'>
      <router-link
        :to="{ name: 'Calendar', query: {
          productId: $route.params.id,
          hostId: userId
        }}"
        class='btn-sm btn-white flex md:ml-2'>
        <CalendarIcon class='-ml-1 mr-2 h-6 w-6 text-gray-400' aria-hidden='true' />
        <span>Calendar</span>
      </router-link>
      <a
        :href="`${viewUrl}`"
        target="_blank"
        class='btn-sm btn-white flex md:ml-2'>
        <EyeIcon class='-ml-1 mr-2 h-6 w-6 text-gray-400' aria-hidden='true' />
        <span>View</span>
      </a>
    </div>
  </div>
</template>

<script>
import { EyeIcon, CalendarIcon } from '@heroicons/vue/outline';
import { useStore } from "vuex";
import { computed } from "vue";
import dayjs from "dayjs";

export default {
  name: "ListingHeader",
  components: {
    EyeIcon,
    CalendarIcon
  },
  setup() {

    const countryList =    [{ name:"-",code:"",id:"" },
                            { name:"Denmark",code:"45",id:"DK",lang:"da/" },
                            { name:"Norway",code:"47",id:"NO",lang:"no/" },
                            { name:"Sweden",code:"46",id:"SE",lang:"sv/" }];


    const store = useStore();

    const title = computed(() => store.state.product.product.generalInfo.title);
    
    const verifiedAt = computed(() => store.state.product.product.generalInfo.verified_at);
    const createdAt = computed(() => store.state.product.product.generalInfo.created_at);
    const userId = computed(() => store.state.product.product.settings.userId)

    const clientUrl = computed(() => process.env.VUE_APP_CLIENT_URL);
    const contentLanguage = computed(() =>
      `/${countryList.filter(x => x.id === store.state.product.product.generalInfo.countryCode)[0]?.lang || ''}`); 
   
    const url = computed(() => `${contentLanguage.value}${store.state.product.product.generalInfo.url}`);
    
    const viewUrl = computed(()=>`${clientUrl.value}${url.value}`.replace('m//','m/'));

    return {
      dayjs,
      title,
      url,
      verifiedAt,
      createdAt,
      clientUrl,
      userId,
      contentLanguage,
      viewUrl
    }
  }
};
</script>
